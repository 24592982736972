import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import Badge from "../../../elements/V2/Badge"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import NumberDisplay from "../../../elements/V2/NumberDisplay"
import Typography from "../../../elements/V2/Typography"

import AddToCartButton from "@components/elements/V2/Buttons/AddToCartButton"
import { PurchasableItem } from "@hooks/V2/storyblok"
import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getUrlFromStoryblokLink } from "@utils/storyblok"
import {
  getAccentColor,
  getBackgroundColorClass,
  getButtonColor,
} from "@utils/V2/color"

type Props = {
  imageUrl: string
  imageAlt: string
  productName: string
  productDescription: string
  priceValue?: string | number
  priceSuperscriptText?: string
  priceAdornmentText?: string
  priceStrikeSuperscriptText?: boolean
  priceSubscriptText?: string
  priceSideScriptText?: string
  learnMoreButtonUrl?: string
  calloutLabel?: string
  badgeText?: string
  badgeAccentColor?: Color
  isPurchasable?: boolean
  purchasableItem: PurchasableItem
  reasonNotAvailable?: string
  waitlistLink?: string
  backgroundColor?: Color
  ctaLabelOverride?: string
} & Partial<Storyblok.PillCTA>

const CardMachine = ({
  imageUrl,
  imageAlt,
  productName,
  productDescription,
  priceValue,
  priceSuperscriptText,
  priceAdornmentText = "R",
  priceStrikeSuperscriptText = false,
  priceSideScriptText,
  priceSubscriptText,
  ctaText = "Buy Now",
  ctaLink,
  learnMoreButtonUrl,
  calloutLabel,
  badgeText,
  badgeAccentColor = Color.Orange,
  isPurchasable = false,
  purchasableItem,
  reasonNotAvailable,
  waitlistLink,
  backgroundColor = Color.LightGray,
  ctaLabelOverride,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)
  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        getBackgroundColorClass(backgroundColor),
        "col-span-4 flex flex-col rounded-20 sm:col-span-2 md:col-span-3 md:rounded-24 lg:col-span-4 lg:rounded-32"
      )}
      {...props}
    >
      <div className="relative flex flex-none items-center justify-center">
        <GatsbyStoryblokImage
          aspectRatio="1:1"
          loadType="eager"
          image={imageUrl}
          alt={imageAlt || "Product image"}
          width={300}
          testId="card-machine-card-image"
          className="max-w-[230px] sm:max-w-[300px]"
        />
        {badgeText && (
          <Badge
            text={badgeText}
            textColor={getAccentColor(badgeAccentColor)}
            accentColor={badgeAccentColor}
            style="solid"
            className="absolute left-[14px] top-[14px] md:left-20 md:top-20"
          />
        )}
        {calloutLabel && (
          <div className="absolute right-8 top-0 flex h-full items-center md:right-[9px] lg:right-[10px]">
            <Typography
              font="grotesk"
              weight="book"
              size="subscript-lg"
              color={getAccentColor(backgroundColor)}
              text={calloutLabel}
              className="origin-center rotate-180 uppercase opacity-50 [writing-mode:vertical-lr]"
            />
          </div>
        )}
      </div>

      <div className="flex flex-grow flex-col gap-16 px-20 pb-24 pt-8 md:gap-20 md:px-24 md:pb-32 md:pt-16 lg:gap-24 lg:px-32 lg:pb-40">
        <div className="flex-grow">
          <Typography
            font="grotesk"
            weight="medium"
            size="lead-md"
            color={getAccentColor(backgroundColor)}
            text={productName}
            className="mb-4"
          />
          <Typography
            font="grotesk"
            weight="book"
            size="body-md"
            color={getAccentColor(backgroundColor)}
            text={productDescription}
          />
        </div>
        <div className="flex flex-none flex-col gap-16 md:gap-20 lg:gap-24">
          <NumberDisplay
            type="price"
            size="small"
            value={priceValue}
            superscriptText={priceSuperscriptText}
            adornmentText={priceAdornmentText}
            strikeSuperscriptText={priceStrikeSuperscriptText}
            adornmentTextPosition="left"
            textColor={getAccentColor(backgroundColor)}
            subscriptText={priceSubscriptText}
            sideScriptText={priceSideScriptText}
          />
          <div className="flex w-full flex-col items-center gap-16">
            <div className="w-full">
              {isPurchasable ? (
                <AddToCartButton
                  style="solid"
                  purchasableItem={purchasableItem}
                  buttonColor={getButtonColor(backgroundColor)}
                  reasonNotAvailable={reasonNotAvailable}
                  waitlistLink={waitlistLink}
                  className="!w-full border-[1.5px]"
                />
              ) : (
                ctaLink && (
                  <PillButton
                    hideIcon
                    size="large"
                    text={ctaLabelOverride ? ctaLabelOverride : ctaText}
                    style="bordered"
                    color={Color.Blue}
                    linkUrl={getUrlFromStoryblokLink(ctaLink)}
                    overrideTextColor={getAccentColor(backgroundColor)}
                    className="!w-full"
                  />
                )
              )}
            </div>
            {learnMoreButtonUrl && (
              <div>
                <TextButton
                  linkUrl={learnMoreButtonUrl}
                  style="text"
                  text="LEARN MORE"
                  color={getAccentColor(backgroundColor)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardMachine
